<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    style="background: rgba(0, 0, 0, 0)"
  >
    <!-- Formulario del edit personal data -->
    <q-card class="text-left q-ma-none q-pa-lg" :style="{ 'font-family': settings.typography }">
      <CloseButton />
        <q-form @submit="editPersonalData" class="q-gutter-md">
         
            <q-item class="q-ma-sm q-pa-none">
              <q-item-section>
                <q-item-label class="text-h6">{{
                  $t("Profile.personal-data.my-data")
                }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-data.email")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="user.email"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-data.please-introduce-email'),
                  ]"
                  lazy-rules
                  maxlength="40"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-data.name")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="user.name"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-data.please-introduce-name'),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-data.surname")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="user.last_name"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-data.please-introduce-surname'),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>
            <!-- <q-item class="q-ma-none q-pa-none">
              <q-item-section>
                <q-item-label class="q-ma-sm">Fecha de nacimiento</q-item-label>
                <q-input
                  filled
                  v-model="personalData.fechaNacimiento"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Por favor, introduzca su mail',
                  ]"
                  lazy-rules
                />
              </q-item-section>
            </q-item> -->
            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-data.phone")
                }}</q-item-label>
                <q-input
                  class="q-ma-none q-pa-none"
                  filled
                  v-model="user.phone"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-data.please-introduce-phone'),
                  ]"
                  lazy-rules
                  maxlength="20"
                />
              </q-item-section>
            </q-item>
         
          <div class="row justify-center">
            <q-btn
              class="button-blue"
              :label="$t('General.save')"
              type="submit"
              :style="{ background: settings.color_primary }"
            >
            </q-btn>
          </div>
        </q-form>
      </q-card>

    <WaitEdit v-model="waitEditDialog" :settings="settings" />
    <SuccessEdit v-model="successEditDialog" :settings="settings" />
    <FailEdit v-model="failEditDialog" :settings="settings" />
  </q-dialog>
</template>
<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
import { mapState, mapActions } from "vuex";
import axios from "../../../../services/Api";
import settings from "@/mixins/settings";

const WaitEdit = defineAsyncComponent(() => import("./dialogs/WaitEdit.vue"));

const SuccessEdit = defineAsyncComponent(() =>
  import("./dialogs/SuccessEdit.vue")
);

const FailEdit = defineAsyncComponent(() => import("./dialogs/FailEdit.vue"));

const CloseButton = defineAsyncComponent(() =>
  import("../../../shared/buttons/CloseButton.vue")
);

export default {
  mixins: [settings],

  components: {
    CloseButton,
    SuccessEdit,
    FailEdit,
    WaitEdit,
  },
  data() {
    return {
      loading: false,
      waitEditDialog: false,
      successEditDialog: false,
      failEditDialog: false,
    };
  },
  props: {
    dialog: {
      default: false,
    },
  },
  setup() {
    return {
      dialog: ref(false),
      maximizedToggle: true,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    // Función que cierra el diálogo
    close() {
      this.$emit("update:dialog", false);
    },

    ...mapActions({ setUser: "auth/setUser" }),

    editPersonalData() {
      this.waitEditDialog = true;
      setTimeout(() => {
        this.onSubmitAddress();
      }, 3000);
    },

    // Funcion para guardar cambios. Primero hace un put concatenando los inputs y luego se hace un get para actualizar la información de usuario
    onSubmitAddress() {
      axios
        .put("front-catalog/customers", {
          name: this.user.name,
          last_name: this.user.last_name,
          email: this.user.email,
          phone: this.user.phone,
        })
        .then(() => {
          axios.get("front-catalog/customers").then(() => {
            this.setUser();
          });
        })
        .catch(() => {
          this.failEditDialog = true;
        })
        .finally(() => {
          this.waitEditDialog = false;
          this.successEditDialog = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    left: 26%;
    max-width: 560px;
    min-height: 610px;
    min-width: 466px;
    position: relative;
    top: 20px;
  }
}

.button-blue {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
}

.q-card {
  background-color: $gray;
  font-family: v-bind("settings.typography");
  border-radius: 25px 25px 25px 0px;
}

.q-field--filled .q-field__control {
  height: 53px !important;
}
</style>
